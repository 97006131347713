<template>
  <div>
    <b-button
      v-if="permissions.includes('novo_modelo')"
      @click="$router.push('/campanhas/modelos/novo')"
      class="mb-2"
      variant="success"
      >Novo Modelo <fa-icon icon="plus"></fa-icon>
    </b-button>

    <Card class="mb-2 pb-3">
      <template #card-body>
        <b-row class="pt-3">
          <b-col>
            <label>Canal</label>
            <b-form-select
              :options="selectOptionsChannels"
              v-model="selected.channel_id"
            ></b-form-select>
          </b-col>
          <b-col md="2">
            <FormSelect
              :title="'Aplicação:'"
              :options="applicationSelectOptions"
              v-model="selected.application_id"
            />
          </b-col>          
        </b-row>
      </template>
    </Card>
    <Card>
      <template #card-body>
        <TableTemplatesList :templates="templates" />
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: {
        channel_id: "",
        application_id: "",
        notification_type_id: "",
        active: "",
        legacy: "",
      },
    };
  },
  components: {
    TableTemplatesList: () => import("./components/TableTemplatesList.vue"),
    FormSelect: () => import("@/components/inputs/Select"),
  },
  computed: {
    permissions() {
      let permissions = [];
      try {
        const response =
          this.$store.state.auth.burgerPermissions.sistemicos.modelos.habilitar;
        if (response) {
          permissions = response;
        }
      } catch (e) {}

      return permissions;
    },
    applications() {
      return this.$store.state.global.applications;
    },
    applicationsById() {
      return _.keyBy(this.applications, "id");
    },
    unityApplications() {
      return this.$store.state.global.unityApplications;
    },
    applicationSelectOptions() {
      const unityApplications = _.map(this.unityApplications, (application) => {
        return {
          description: this.applicationsById[application.application_id].name,
          value: application.application_id,
        };
      });
      return [{ description: "Todas", value: "" }, ...unityApplications];
    },
    notificationSelectOptions() {
      const normalized = _.map(
        this.$store.state.global.notificationTypes,
        (item) => {
          return { description: item.description, value: item.id };
        }
      );

      return [{ description: "Todos", value: "" }, ...normalized];
    },
    profileSelectOptions() {
      const normalized = _.map(this.$store.state.global.profiles, (item) => {
        return { description: item.name, value: item.id };
      });

      return [{ description: "Todos", value: "" }, ...normalized];
    },
    selectOptionsChannels() {
      const firstOption = {
        text: "Todos",
        value: "",
        disabled: true,
      };
      const normalized = _.map(this.$store.state.global.channels, (item) => {
        return {
          text: item.channel,
          value: item.id,
          disabled: item.active == "1" ? false : true,
        };
      });
      return [firstOption, ...normalized];
    },
    templates() {
      const campaignModels = _.filter(this.$store.state.global.models, (model)=>{
        return model.notification_type_id == null
      })
      let byChannel = [];
      if (this.selected.channel_id == "") {
        byChannel = campaignModels;
      } else {
        byChannel = _.filter(campaignModels, (model) => {
          return model.channel_id == this.selected.channel_id;
        });
      }
      let byApplication = [];
      if (this.selected.application_id == "") {
        byApplication = byChannel;
      } else {
        byApplication = _.filter(byChannel, (model) => {
          return model.application_id == this.selected.application_id;
        });
      }     

      const sorted = _.sortBy(byApplication, [
        "application_name"        
      ]);
      return sorted;
    }
  }  
};
</script>

<style>
</style>